/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&family=Raleway&family=Roboto&display=swap');

@font-face {
  font-family: 'Azonix';
  src: url('./assets/fonts/Azonix.otf');
}

:root {
  --primary: #0c2444;
  --primary-light: #0e2c52;
  --secondary: #ff6000;
  --info: #0c2444;
  --grey: #e0e0e0;
  --dark-grey: #000000;
}

body,
html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: var(--primary);
  overflow: hidden;
}

.title {
  font-family: 'Azonix', sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.35em;
}

.bg {
  position: relative;
  background-color: var(--dark-grey);
}

.bg * {
  position: relative;
}

.bg-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.33;
  filter: grayscale(0.1);
  background-image: url('./assets/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 10000;
  background-image: url('./assets/development.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.uat {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 10000;
  background-image: url('./assets/uat.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.ptft-container {
  background-color: white;
}

.ptft-sub-container {
  background-color: white;
}

.ptft-title {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.ptft-title .material-icons {
  font-size: 1.75em;
  margin-right: 10px;
}

.material-icons {
  user-select: none;
}

table {
  border-radius: 0.25rem;
}

dialog,
ngb-modal-window {
  background: none;
  backdrop-filter: blur(5px);
}

.modal-title {
  display: flex;
  align-items: center;
}

.modal-title .material-icons {
  margin-right: 10px;
  font-size: 1.75em;
}

.d-block.modal.fade.show {
  z-index: 9999;
}

ngb-alert {
  margin: 15px 0 15px 0;
}

.form-label {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.form-label .material-icons {
  margin-right: 5px;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
}

/* spinner */
a {
  cursor: pointer;
}

.spinner-title {
  text-align: center;
  font-size: 40px;
  color: var(--primary);
}
.spinner-subtitle {
  text-align: center;
  font-size: 20px;
  color: var(--primary);
}
.spinner-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.spinner-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.spinner-loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary-light);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.spinner-loader:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--secondary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/** END SPINNER **/

.container-error {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.container-error h3 {
  font-size: 1.75em;
}

.leaflet-control-contributions {
  width: 30px;
  height: 30px;
  background: url(./assets/contributions.svg);
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 2px;
}

.leaflet-control-contributions:hover {
  cursor: pointer;
}

.tox-promotion-link {
  display: none;
  visibility: hidden;
}

.tox-statusbar__branding {
  display: none;
  visibility: hidden;
}

.btn-invisible {
  border-width: 0;
  background-color: transparent;
}
